import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const MsHeader = () => {
  const { channelUrl } = useSelector(
    (state) => state.partnerChannelNameSlice.marutiUserDetails
  );
  return (
    <header>

      <div className="flex justify-between  pl-6 pr-6 pt-1 pb-1 border h-[60px] shadow-md ">
        <div className="flex items-center gap-3">
          <img
            src="/assets/hdfc-ergo-logo.webp"
            alt="hdfcErgo-logo"
            className="w-[60px] h-[40px]"
          />
          <h1 className="font-bold">KYC</h1>
        </div>
        {channelUrl === "maruti" ? (
          <div className="flex items-center">
            <img
              src="/assets/ms_logo.png"
              alt="MS-logo"
              className="w-[100px] h-[20px] md:w-[182px] md:h-[30px]"
            />
          </div>
        ) : null}
      </div>
    </header>
  );
};

export default MsHeader;
